<!-- Start Footer Area -->
<footer
    class="footer-area"
    [ngClass]="{'d-none': router.url === '/demo-7' || router.url === '/personal' || router.url === '/business' || router.url === '/how-it-works' || router.url === '/about-us-2' || router.url === '/contact-2'}"
>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/black-logo.png" alt="logo"></a>
                        <p>Quis ipsum suspendisse ultrices gravida commodo. Risus commodo veliliee vel viverra maecenas accumsan lacus vel facilisis.</p>
                    </div>
                    <ul class="social-links">
                        <li><a href="https://www.facebook.com/profile.php?id=61555350546364" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <!--<li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>-->
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pr-5">
                    <h3>Company</h3>

                    <ul class="list">
                        <li><a routerLink="/">About Us</a></li>
                        <li><a routerLink="/">Services</a></li>
                        <li><a routerLink="/">Features</a></li>
                        <li><a routerLink="/">Our Pricing</a></li>
                        <li><a routerLink="/">Latest News</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>

                    <ul class="list">
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Condition</a></li>
                        <li><a routerLink="/">Community</a></li>
                        <li><a routerLink="/">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="footer-contact-info">
                        <li><span>Location:</span> 964 High House Road Unit #4110, Cary, NC 27513</li>
                        <li><span>Email:</span> <a href="mailto:support@kulipa.com">support@kulipa.com</a></li>
                        <li><span>Phone:</span> <a href="tel:+18023472236">+1-(802) 347-2236</a></li>
                        <li><span>Fax:</span> <a href="tel:+18336665386">+1-(833) 666-5386</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <p>© Kulipa is Proudly Owned by <a href="https://diracian.com/" target="_blank">Diracian</a></p>
        </div>
    </div>
    <div class="map-image"><img src="assets/img/map.png" alt="map"></div>
</footer>

<footer
    class="ctp-footer-area pt-100 d-none"
    [ngClass]="{'d-block': router.url === '/demo-7' || router.url === '/personal' || router.url === '/business' || router.url === '/how-it-works' || router.url === '/about-us-2' || router.url === '/contact-2'}"
>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="logo">
                        </a>
                    </div>
                    <ul class="social-links">
                        <span>Find us on social media</span>
                        <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Company</h3>
                    <ul class="links">
                        <li>
                            <a routerLink="/about-two">About us</a>
                        </li>
                        <li>
                            <a routerLink="/how-it-works">How it works</a>
                        </li>
                        <li>
                            <a routerLink="/">Mobile apps</a>
                        </li>
                        <li>
                            <a routerLink="/">Reviews</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Support</h3>
                    <ul class="links">
                        <li>
                            <a routerLink="/">Terms and conditions</a>
                        </li>
                        <li>
                            <a routerLink="/">Privacy policy</a>
                        </li>
                        <li>
                            <a routerLink="/contact-two">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="ctp-footer-widget">
                    <h3>Need help?</h3>
                    <ul class="info">
                        <li>
                            <span>Location: </span>
                            964 High House Road
                            Unit #4110
                            Cary, NC 27513
                        </li>
                        <li>
                            <span>Email: </span>
                            <a href="mailto:support@kulipa.com">support@kulipa.com</a>
                        </li>
                        <li>
                            <span>Email: </span>
                            <a href="tel:321984754">+ (321) 984 754</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div><!--
        <div class="copyright-area">
            <p>© Klob is proudly created by <a href="https://envytheme.com/" target="_blank"> EnvyTheme</a></p>
        </div>-->
    </div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i></div>
