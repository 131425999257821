<!-- Start Navbar Area -->
<div class="navbar-area {{layoutClass}}">
    <div class="klob-nav">
        <div
            class="container"
            [ngClass]="{'container-fluid': router.url === '/demo-7'}"
        >
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/logo.png" alt="logo">
                    <img src="assets/img/black-logo.png" alt="logo">
                </a>
                <button
                    class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Home <!--<i class="fas fa-chevron-down"></i>--></a>
                            <!--<ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-link">Banking & Payment Processing</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-2" routerLinkActive="active" class="nav-link">Loan Providers</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-3" routerLinkActive="active" class="nav-link">Digital Banking</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-4" routerLinkActive="active" class="nav-link">Financial Service Provider</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-5" routerLinkActive="active" class="nav-link">Bankers & Entrepreneurs</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-6" routerLinkActive="active" class="nav-link">Social Finance Agency</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-7" routerLinkActive="active" class="nav-link">Currency Transfer Provider</a>
                                </li>
                            </ul>-->
                        </li>
                        <li class="nav-item">
                            <a routerLink="/about-us" routerLinkActive="active" class="nav-link">About Us</a>
                        </li>
                        <!--<li class="nav-item">
                            <a routerLink="/features" routerLinkActive="active" class="nav-link">Features</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">
                                Pages <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/about-us-2" routerLinkActive="active" class="nav-link">About Us Two</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/personal" routerLinkActive="active" class="nav-link">Personal</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/business" routerLinkActive="active" class="nav-link">Business</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/how-it-works" routerLinkActive="active" class="nav-link">How It Works</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/team" routerLinkActive="active" class="nav-link">Team</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/error-404" routerLinkActive="active" class="nav-link">404 Error Page</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/faq" routerLinkActive="active" class="nav-link">FAQ</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/signup" routerLinkActive="active" class="nav-link">Signup</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/signin" routerLinkActive="active" class="nav-link">Signin</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/contact-2" routerLinkActive="active" class="nav-link">Contact Us Two</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/pricing" routerLinkActive="active" class="nav-link">Pricing</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Blog <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/blog" routerLinkActive="active" class="nav-link">Blog</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/blog-right-sidebar" routerLinkActive="active" class="nav-link">Blog Right Sidebar</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/blog-details" routerLinkActive="active" class="nav-link">Blog Details</a>
                                </li>
                            </ul>
                        </li>-->
                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                        </li>
                    </ul>
                    <div class="others-options">
                        <a routerLink="/signin" routerLinkActive="active" class="login-btn"><i class="flaticon-user"></i> Log In</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
