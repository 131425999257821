<!-- Start Main Banner Area -->
<div class="banner-wrapper">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-wrapper-content">
                    <h1>Powering the ways you do business.</h1>
                    <p>Work smarter, not harder.  Let our hardware and software work for you.</p>
                    <a routerLink="/" class="btn btn-primary">Get Started</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="banner-wrapper-image">
                    <img src="assets/img/banner-img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Main Banner Area -->

<!-- Start Partner Area --><!--
<div class="partner-area-two ptb-70">
    <div class="container">
        <div class="partner-slides owl-carousel owl-theme">
            <div class="single-partner-item">
                <img src="assets/img/partner1.png" alt="image">
            </div>
            <div class="single-partner-item">
                <img src="assets/img/partner2.png" alt="image">
            </div>
            <div class="single-partner-item">
                <img src="assets/img/partner3.png" alt="image">
            </div>
            <div class="single-partner-item">
                <img src="assets/img/partner4.png" alt="image">
            </div>
            <div class="single-partner-item">
                <img src="assets/img/partner5.png" alt="image">
            </div>
        </div>
    </div>
</div>-->
<!-- End Partner Area -->

<!-- Start Payment Experience Area -->
<div class="payment-experience-area bg-f4fcff ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="payment-experience-content">
                    <h2>Create seamless payment experiences</h2>
                    <p>We support over 30+ ways to accept transactions.  We are here to support your business, we work and support your business.</p>
                    <p></p>
                    <a routerLink="/" class="link-btn">Get Started</a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="payment-experience-image text-center">
                    <img src="assets/img/experience-img1.png" alt="image">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-payment-experience-box">
                    <div class="icon">
                        <i class="fas fa-chart-line"></i>
                    </div>
                    <h3>Faster Growth</h3>
                    <p>Since we are taking care of the payments side of the business that frees up more time to focus on growing your business.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-payment-experience-box">
                    <div class="icon">
                        <i class="fab fa-audible"></i>
                    </div>
                    <h3>Amazing Experiences</h3>
                    <p>We provide various ways to move money.  We make it an easy experience.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-payment-experience-box">
                    <div class="icon">
                        <i class="fas fa-credit-card"></i>
                    </div>
                    <h3>Global Payments</h3>
                    <p>We try to be your one stop for all your processing needs.  PayPal, BitCoin, PayU, UnionPay, etc.</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-payment-experience-box">
                    <div class="icon">
                        <i class="fab fa-expeditedssl"></i>
                    </div>
                    <h3>Secure Payments</h3>
                    <p>We are PCI Compliant.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Payment Experience Area -->

<!-- Start Support Area -->
<div class="support-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="support-image">
                    <img src="assets/img/support-img1.png" alt="image">
                    <img src="assets/img/support-img2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="support-content">
                    <h2>Global support in a range of languages</h2>
                    <p>We recognize that there is a diverse and global user base, and we want to enhance user experience and accessibility.</p>
                    <p>We try to provide language options in our settings.  Users can choose their preferred language for the interface, which make it more user-friendly experience.</p>
                    <a routerLink="/" class="btn btn-primary">Find Out More</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Support Area -->

<!-- Start Business Area -->
<!--<div class="business-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="business-content">
                    <h2>Made to grow with your business</h2>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-business-box">
                                <h3>International payments</h3>
                                <p>Labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-business-box">
                                <h3>Completely customisable</h3>
                                <p>Labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-business-box">
                                <h3>Real-time alerts</h3>
                                <p>Labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-business-box">
                                <h3>Fully safe and secure</h3>
                                <p>Labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="business-image">
                    <img src="assets/img/business-img.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- End Business Area -->

<!-- Start Testimonials Area -->
<!--<div class="testimonials-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>Klob score 4.8 out of 5, from 55,495 reviews</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-testimonials-box">
                    <div class="rating">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <h3>John Smith <span>CTO at EnvyThme</span></h3>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-testimonials-box">
                    <div class="rating">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <h3>Sarah Taylor <span>CTO at ThemeForest</span></h3>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-testimonials-box">
                    <div class="rating">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <h3>James Andy <span>CEO at Envato</span></h3>
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- End Testimonials Area -->

<!-- Start Success Story Area -->
<!--<div class="success-story-area ptb-70 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>Business success story</h2>
        </div>
        <div class="success-story-inner">
            <div class="success-story-slides owl-carousel owl-theme">
                <div class="single-success-story-box">
                    <div class="row m-0 align-items-center">
                        <div class="col-lg-6 col-md-6 p-0">
                            <div class="content">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                                <h3>John Smith <span>CTO at EnvyTheme</span></h3>
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="btn btn-primary popup-youtube">Watch Video</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 p-0">
                            <div class="image">
                                <img src="assets/img/success-story1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="single-success-story-box">
                    <div class="row m-0 align-items-center">
                        <div class="col-lg-6 col-md-6 p-0">
                            <div class="content">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                                <h3>John Smith <span>CTO at EnvyTheme</span></h3>
                                <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="btn btn-primary popup-youtube">Watch Video</a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 p-0">
                            <div class="image">
                                <img src="assets/img/success-story1.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- End Success Story Area -->

<!-- Start Global Area -->
<!--<div class="global-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>Trusted all over the world</h2>
            <div class="bar"></div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="global-content">
                    <ul>
                        <li>10 million customers</li>
                        <li>2M+ API request per day</li>
                        <li>75+ countries using</li>
                        <li>2+ sent every month</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="global-image text-center">
                    <img src="assets/img/global-img.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- End Global Area -->

<!-- Start Payment Features Area -->
<!--<div class="payment-features-area ptb-70">
    <div class="container">
        <div class="payment-features-overview">
            <div class="payment-features-image">
                <div class="image">
                    <img src="assets/img/payment-features1.jpg" alt="image">
                </div>
            </div>
            <div class="payment-features-content">
                <div class="content">
                    <h2>Retail Payment Processing</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/" class="link-btn">Get started now</a>
                </div>
            </div>
        </div>
        <div class="payment-features-overview">
            <div class="payment-features-content">
                <div class="content">
                    <h2>Online & eCom Payment Processing</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/" class="link-btn">Get started now</a>
                </div>
            </div>
            <div class="payment-features-image">
                <div class="image">
                    <img src="assets/img/payment-features2.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>-->
<!-- End Payment Features Area -->

<!-- Start Get Started Area -->
<div class="get-started-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="get-started-title">
                    <h2>Ready to get started?</h2>
                    <a routerLink="/sign-up" class="btn btn-primary">Sign Up Now</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="get-started-content">
                    <p>Sign up, and find out more about our solutions.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Get Started Area -->
