<!-- Start Account Create Area -->
<div class="account-create-area">
    <div class="container">
        <div class="account-create-content">
            <h2>Apply for an account in minutes</h2>
            <p>Get your Kulipa account today!</p>
            <a routerLink="/" class="btn btn-primary">Get Your Kulipa Account</a>
        </div>
    </div>
</div>
<!-- End Account Create Area -->
